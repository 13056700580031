<template>
  <sign-page
    title-text="员工数据(统计)"
    v-loading="loading"
    :request="request"
    :search-parms="searchParms"
    :column-list="columnList"
    @clickTitleMenu="clickTitleMenu"
    :titleMenus="titleMenus"
    :initSearchData="{year: this.years[1]}"
    ref="countBase"
    :tableActions="[{key: 'update', label: '修改'}]"
    @tableAction="tableAction"
    :tableActionsFixed="true"
    :need-data-file="true">
    <fm-modal v-model="modal" v-if="modal" width="1100px">
      <base-form @close="modal = false" @dataUpdate="$refs.countBase.loadData()" :source-data="chooseData"></base-form>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  countWorkerRequest as request,
  countGroupRequest
} from '../../api'

import {
  getYears,
  countWorkerColumns
} from './lib'

import BaseForm from './cmp/form'

export default {
  components: {
    BaseForm
  },
  methods: {
    tableAction (parm) {
      this.chooseData = parm.data
      if (parm.action === 'update') {
        this.modal = true
      }
    },
    async clickTitleMenu (parm, vm) {
      this.loading = true
      if (parm === 'batch') {
        if (!this.chooeCountGroup) {
          await countGroupRequest.add({
            name: this.chooseYear,
            startTime: this.chooseYear + '-01-01 00:00:00',
            endTime: this.chooseYear + '-12-31 23:59:59'
          })
          this.countGroups = await countGroupRequest.get()
          this.chooeCountGroup = this.countGroups.find(v => v.name === this.chooseYear)
        }
        await countGroupRequest.generateDetail(this.chooeCountGroup.id)
      } else {
        this.loading = false
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定清空' + this.chooseYear + '年数据吗?'})
        if (!result) {
          return
        }
        this.loading = true
        await countGroupRequest.delDetail(this.chooeCountGroup.id)
      }
      vm.loadData()
      this.loading = false
    },
    async loadData (parm) {
      if (!parm || !parm.year) {
        this.$notice.info({
          title: '系统提示',
          desc: '请选择年份。'
        })
        return
      }
      this.chooseYear = parm.year
      if (this.countGroups === null) {
        this.countGroups = await countGroupRequest.get()
      }
      this.chooeCountGroup = this.countGroups.find(v => v.name === parm.year)
      this.dataLength = null
      let p = {
        countGroupId: this.chooeCountGroup ? this.chooeCountGroup.id : -1
      }
      let datas = await request.get(p)
      datas.forEach(v => {
        Object.keys(v).forEach(key => {
          if (key.indexOf('is') === 0 || key.indexOf('have') === 0) {
            v[key] = v[key] === null ? null : (v[key] ? '1' : '0')
          }
        })
      })
      this.dataLength = datas.length
      return datas
    }
  },
  computed: {
    titleMenus () {
      let data = []
      if (this.chooeCountGroup) {
        if (this.dataLength !== null) {
          if (this.dataLength === 0) {
            data = [{
              key: 'batch',
              label: '批量生成'
            }]
          } else {
            data = [{
              key: 'del_all',
              label: '清空数据'
            }]
          }
        }
      } else if (this.chooseYear) {
        data = [{
          key: 'batch',
          label: '批量生成'
        }]
      }
      return data
    },
    searchParms () {
      return [{
        type: 'select',
        placeholder: '年份',
        selectDatas: this.years.map((v) => {
          return {
            key: v,
            label: v
          }
        }),
        key: 'year',
        default: this.years[1]
      }]
    },
    columnList: {
      get () {
        let data = countWorkerColumns.map(v => {
          let cItem = Object.assign({}, v, {})
          if (cItem.field.indexOf('is') === 0 || cItem.field.indexOf('have') === 0) {
            cItem.render = (h, rowData) => {
              return h('div', rowData[cItem.field] === '1' ? '是' : '否')
            }
          }
          return cItem
        })
        return data
      }
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      modal: false,
      chooseData: null,
      years: getYears(),
      countGroups: null,
      chooeCountGroup: null,
      dataLength: null,
      loading: false,
      chooseYear: null
    }
  }
}
</script>
