<template>
  <div class="content count-worker-data">
    <fm-title :title-text="'员工统计数据(' + countWorkerData.name + ')'"></fm-title>
    <fm-form :inline="4" label-alone label-align="left">
      <fm-form-item label="姓名">
        <fm-input-new v-model="countWorkerData.name" placeholder="姓名" :disabled="true"/>
      </fm-form-item>
      <fm-form-item :inline="2" label="性别">
        <fm-radio-group v-model="countWorkerData.sex">
          <fm-radio value="男" label="男"></fm-radio>
          <fm-radio value="女" label="女"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="年龄">
        <fm-input-number v-model="countWorkerData.age" placeholder="年龄"/>
      </fm-form-item>
      <fm-form-item label="民族">
        <fm-select absolute filterable :clearable="true" v-model="countWorkerData.clan">
          <fm-option v-for="item in workerClanList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="政治面貌">
        <fm-select absolute filterable :clearable="true" v-model="countWorkerData.politicalStatus">
          <fm-option v-for="item in workerPoliticalStatusList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="是否是残疾人">
        <fm-radio-group v-model="countWorkerData.isDisabled">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="是否是运动员">
        <fm-radio-group v-model="countWorkerData.isAthlete">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="是否是港澳居民">
        <fm-radio-group v-model="countWorkerData.isGajm">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="是否是台湾同胞">
        <fm-radio-group v-model="countWorkerData.isTwtb">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="是否是外籍人士">
        <fm-radio-group v-model="countWorkerData.isWjrs">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="是否是留学回国人士">
        <fm-radio-group v-model="countWorkerData.isLxgg">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="学历">
        <fm-select absolute filterable :clearable="true" v-model="countWorkerData.education">
          <fm-option v-for="item in workerEducationList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="学位">
        <fm-select absolute filterable :clearable="true" v-model="countWorkerData.degree">
          <fm-option v-for="item in workerEduDegrees" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="是否在编">
        <fm-radio-group v-model="countWorkerData.isZb">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="是否劳务派遣人员">
        <fm-radio-group v-model="countWorkerData.isNwpq">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="是否编外聘用人员">
        <fm-radio-group v-model="countWorkerData.isBwpy">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="专业技术人员专业大类">
        <fm-select absolute filterable :clearable="true" v-model="countWorkerData.bigProType">
          <fm-option v-for="item in ['工程技术人员', '卫生技术人员', '会计人员', '其他人员']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="是否是卫生">
        <fm-radio-group v-model="countWorkerData.isWs">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="是否是卫生和社会工作">
        <fm-radio-group v-model="countWorkerData.isWshshgz">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="是否特设岗位人员">
        <fm-radio-group v-model="countWorkerData.isTgts">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="是否具有职业资格">
        <fm-radio-group v-model="countWorkerData.haveZyzg">
          <fm-radio value="1" label="是"></fm-radio>
          <fm-radio value="0" label="否"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="所属层级">
        <fm-select absolute filterable :clearable="true" v-model="countWorkerData.level">
          <fm-option v-for="item in ['中央', '省（区、市）', '地（市、州、盟）', '县（市、区、旗）', '乡（镇）']" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>
    <jobTitleForm ref="jobTitleForm" @dataChange="dataChange" :countWorkerJobTitleList="countWorkerData.countWorkerJobTitleList || []"></jobTitleForm>
    <div class="modal-footer-btns">
        <fm-btn @click="save" :v-loadingx="loading">确定</fm-btn>
        <fm-btn @click="$emit('close')">取消</fm-btn>
      </div>
  </div>
</template>

<script>
import {
  countWorkerRequest
} from '../../../api'

import jobTitleForm from './jobTitleForm'

export default {
  components: {
    jobTitleForm
  },
  props: {
    sourceData: {
      type: Object
    }
  },
  data () {
    return {
      countWorkerData: {},
      newCountWorkerJobTitleList: [],
      loading: false
    }
  },
  watch: {
    sourceData: {
      handler () {
        this.countWorkerData = JSON.parse(JSON.stringify(this.sourceData))
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    workerEduDegrees () {
      return ['博士', '硕士', '学士'].map(l => {
        return {
          key: l,
          label: l
        }
      })
    },
    workerClanList () {
      return this.$store.getters.selectDatas['worker_clan_list']
    },
    workerPoliticalStatusList () {
      return this.$store.getters.selectDatas['worker_political_status_list']
    },
    workerEducationList () {
      return ['研究生', '大学本科', '大学专科', '中专', '高中', '初中', '小学'].map(l => {
        return {
          key: l,
          label: l
        }
      })
    }
  },
  methods: {
    async save () {
      if (!this.$refs.jobTitleForm.check()) {
        return
      }
      this.loading = true
      let parm = Object.assign({}, this.countWorkerData, {
        countWorkerJobTitleList: this.newCountWorkerJobTitleList
      })
      await countWorkerRequest.update(parm.id, parm)
      this.$emit('dataUpdate')
      this.$emit('close')
      this.loading = false
    },
    dataChange (data) {
      this.newCountWorkerJobTitleList = data
    }
  }
}
</script>

<style scoped lang="less">

.count-worker-data {
  .fm-form-inline {
    padding: 0 0 0 16px !important;
  }
}
</style>
